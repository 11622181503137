body {
  background-image: url("https://static.wixstatic.com/media/6aa3f8_825ca49465dd4267a73683648036bcf3~mv2.jpg/v1/fill/w_2500,h_1665,al_c/6aa3f8_825ca49465dd4267a73683648036bcf3~mv2.jpg"); /* The image used */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-y: scroll;
}

h1,
h2,
p {
  color: white;
  align-items: center;
}

.div-text {
  margin-left: auto;
  margin-right: auto;
  width: 50%; /* or any specific width */
}

.yourContainerClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  text-align: center;
}
