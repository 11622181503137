@import url(https://fonts.googleapis.com/css?family=Montserrat);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

$colorCollection: (
  #7c0a12,
  #fec641,
  #63c13b,
  #a50e1c,
  #5c6c94,
  #6b0d05,
  #7d0006,
  #bb3a36,
  #fee34a,
  #2f8a27
);

/* Config */
$lights: 150; // match lights in mark up
$textColor: #ffffff;
$totalAnimation: 5;
$lightSize: 75;
$blurLevel: 2;

/* Pretty Lights */
.bokeh {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.light {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
}

@for $i from 1 through $lights {
  $lightWidth: $lightSize + random(25);
  $lightHeight: $lightWidth;
  $lightVertical: random(100);
  $lightHorizontal: random(100);
  $lightBlur: $blurLevel + random($blurLevel);
  $lightDelay: 10 + random(25);
  $animation: 1 + random($totalAnimation);

  .light:nth-child(#{$i}) {
    width: $lightWidth + px;
    height: $lightHeight + px;
    top: #{$lightVertical + "%"};
    left: #{$lightHorizontal + "%"};
    background: nth($colorCollection, random(length($colorCollection)));
    filter: blur($lightBlur + px);
    animation: #{$lightDelay + s} #{"light" + $animation} linear infinite;
  }
}

@for $i from 1 through $totalAnimation {
  @keyframes light#{$i} {
    25% {
      opacity: (25 + random(50)) * 0.01;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: (25 + random(50)) * 0.01;
    }
    100% {
      opacity: 0;
    }
  }
}

/* Other Stuff */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  background-image: linear-gradient(#0a0707 0%, #110c0d 50%, #0c090a 100%);
}

.my-special-h1 {
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 0px 50px rgba($textColor, 0.5);
  color: $textColor;
  position: absolute;
  top: 25%;
  z-index: 10;
  opacity: 0.9;
  left: 0%;
  right: 0%;
}
